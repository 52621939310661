<script>
import api from '@/command/api'
import DetailFormGroup from '../../../components/DetailFormGroup'
export default {
  name: 'investmentDetail',
  data() {
    return {
      ...api.command.getState(),
    }
  },
  mounted() {
    const { id } = this.$route.query
    id &&
      api.command.getDetail.call(this, {
        url: `/config/farmConfigInvest/get?id=${id}`,
      })
  },
  methods: {
    getForm1() {
      return {
        title: '基本信息',
        type: 'cardForm',
        data: [
          {
            name: '招商帮扶标题(50字符内)',
            type: 'input',
            cols: 12,
            key: 'name',
            maxLength: 50,
            rules: [
              {
                required: true,
                type: 'string',
              },
            ],
          },
          {
            name: '公告类型',
            type: 'select',
            cols: 12,
            key: 'investType',
            typeData: [
              {
                name: '平台公告',
                value: '0',
              },
              {
                name: '文章推荐',
                value: '1',
              },
              {
                name: '数字农业',
                value: '2',
              },
              {
                name: '乡村节庆',
                value: '3',
              },
              {
                name: '节气游乡村',
                value: '4',
              },
              {
                name: '非遗传承',
                value: '5',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择公告类型',
              },
            ],
          },
          {
            name: '公告栏窗口',
            type: 'radioButton',
            key: 'investShow',
            cols: 12,
            typeData: [
              {
                name: '不展示',
                value: '1',
              },
              {
                name: '展示',
                value: '0',
              },
            ],
            rules: [
              {
                required: true,
                type: 'string',
                message: '请选择公告栏窗口',
              },
            ],
          },
        ],
      }
    },
    getForm2() {
      return {
        title: '图片上传',
        type: 'cardForm',
        data: [
          {
            name: '上传图片',
            type: 'upload',
            cols: 24,
            maxLength: 1,
            key: 'imgUrl',
            rules: [
              {
                required: true,
                type: 'string',
                message: '请上传图片',
              },
            ],
          },
        ],
      }
    },
    getForm7() {
      return {
        type: 'cardForm',
        cols: 24,
        data: [
          {
            type: 'rich',
            key: 'content',
            cols: 24,
          },
        ],
      }
    },
    getFoot() {
      const left = [
        {
          name: '提交',
          type: 'primary',
          loading: this.loading,
          sumbit: true,
          onClick: (data) => {
            api.command[this.detail.id ? 'editPost' : 'create'].call(this, {
              url: `/config/farmConfigInvest/${this.detail.id ? 'update' : 'add'}`,
              params: {
                ...data,
              },
              isPost: false,
            })
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
        {
          name: '取消',
          type: 'default',
          onClick: () => {
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      const right = [
        {
          name: '删除',
          popTitle: '确认删除吗?',
          isPop: true,
          type: 'danger',
          ghost: true,
          onClick: () => {
            api.command.delPost.call(this, {
              url: `/config/farmConfigInvest/delete?id=${this.detail.id}`,
              isPost: false,
            })
            setTimeout(() => {
              this.$router.back()
            }, 500)
          },
        },
      ]
      return {
        left,
        right: this.detail.id && right,
      }
    },
  },
  render() {
    return (
      <DetailFormGroup
        form={this.detail}
        foot={this.getFoot()}
        data={[this.getForm1(), this.getForm2(), this.getForm7()]}
      />
    )
  },
}
</script>
<style lang="less" scoped>
.entityInformation-detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
</style>
